/// <reference path="o365.pwa.declaration.sw.strategies.O365CacheAndNetworkRaceStrategy.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { StrategyHandler } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { Request, Response } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

import type * as O365CacheAndNetworkRaceStrategyModule from 'o365.pwa.declaration.sw.strategies.O365CacheAndNetworkRaceStrategy.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    class O365CacheAndNetworkRaceStrategy extends self.workbox.strategies.Strategy implements O365CacheAndNetworkRaceStrategyModule.O365CacheAndNetworkRaceStrategy {
        constructor(options: O365CacheAndNetworkRaceStrategyModule.O365CacheAndNetworkRaceStrategyOptions = {}) {
            super(options);
        }

        async _handle(request: Request, handler: StrategyHandler): Promise<Response | undefined> {
            const cachePromise = handler.cacheMatch(request);
            const networkPromise = handler.fetch(request);

            return new Promise((resolve, reject) => {
                networkPromise.then((val) => {
                    console.log("network resolve");
                    resolve(val);
                });
                cachePromise.then((response) => {
                    console.log("cache resolve");
                    response && resolve(response)
                });

                Promise.allSettled([networkPromise, cachePromise]).then(results => {
                    const [networkResult, cacheResult] = results;

                    if (networkResult.status === 'rejected' && cacheResult.status === 'fulfilled' && !cacheResult.value) {
                        reject(networkResult.reason);
                        console.log("network reject");
                    }
                })
            })
        }
    }


    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.strategies.O365CacheAndNetworkRaceStrategy.d.ts')>({ O365CacheAndNetworkRaceStrategy, self });
})();
